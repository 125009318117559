exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cinema-hd-alternatives-js": () => import("./../../../src/pages/cinema-hd-alternatives.js" /* webpackChunkName: "component---src-pages-cinema-hd-alternatives-js" */),
  "component---src-pages-cinema-hd-firestick-js": () => import("./../../../src/pages/cinema-hd-firestick.js" /* webpackChunkName: "component---src-pages-cinema-hd-firestick-js" */),
  "component---src-pages-cinema-hd-for-pc-js": () => import("./../../../src/pages/cinema-hd-for-pc.js" /* webpackChunkName: "component---src-pages-cinema-hd-for-pc-js" */),
  "component---src-pages-cinema-hd-ios-js": () => import("./../../../src/pages/cinema-hd-ios.js" /* webpackChunkName: "component---src-pages-cinema-hd-ios-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */)
}

